.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
    /*background-color: #282c34;*/
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    font-family: 'Avenir Next LT Pro Regular', 'Avenir Next LT Pro';
    /*font-size: calc(10px + 2vmin);*/
    font-size:18px;
    color: #2B4651;
    margin:20px auto;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.App-btn {
    padding: 10px;
    border: 1px solid #57849B;
    font-family: 'Avenir Next LT Pro Demi', 'Avenir Next LT Pro';
    font-size: 14px;
    text-transform: uppercase;
    color: #57849B;
    font-weight: 600;
    background:#fff;
    cursor:pointer;
}

.warning {
    font-family: 'Avenir Next LT Pro Bold', 'Avenir Next LT Pro';
    font-size: 14px;
    color: #8A6A2C;
    font-weight: 700;
    padding:5px 50px;
    background: rgba(250, 240, 219, 1);
    margin: 20px auto;
}